import React from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ isOpen, onClose, service }) => {
  if (!isOpen) return null;
console.log(service)
  return ReactDOM.createPortal(
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
        <h2 className="text-2xl font-bold text-purple-700 mb-4">{service.title}</h2>
        <p className="text-gray-700 mb-4">{service.description}</p>
        <p className="text-gray-900 font-semibold mb-4">Price: <span dangerouslySetInnerHTML={{ __html: service.price }}></span></p>
        <button onClick={onClose} className="bg-purple-600 text-white py-2 px-4 rounded hover:bg-purple-700">
          Close
        </button>
      </div>
    </div>,
    document.body
  );
};

export default Modal;

// import React from 'react';

// const Footer = () => {
//   return (
//     <footer className="bg-purple-600 text-white p-4 text-center">
//       <p>&copy; 2024 Parlor Name. All rights reserved.</p>
//     </footer>
//   );
// };

// export default Footer;


import React from 'react';
import { FaWhatsapp, FaFacebook, FaInstagram } from 'react-icons/fa';  // Import icons from react-icons
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-purple-600 text-white py-10 relative">
      <div className="container mx-auto px-4 flex flex-wrap justify-between items-center">
        {/* Logo or Footer Brand */}
        <div className="mb-4">
          <h2 className="text-lg font-bold">Parlor Name</h2>
          <p>&copy; 2024 All rights reserved.</p>
        </div>
        
        {/* Quick Links */}
        <div className="mb-4">
          <h3 className="font-semibold mb-2">Quick Links</h3>
          <ul>
            <li><Link to="services" className="hover:underline">Services</Link></li>
            <li><Link to="contact" className="hover:underline">Contact</Link></li>
          </ul>
        </div>
        
        {/* Social Icons */}
        <div className="mb-4">
          <h3 className="font-semibold mb-2">Follow Us</h3>
          {/* <div className="flex space-x-4"> */}
          <div className="flex items-center justify-center">
            {/* <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <FaFacebook className="text-2xl hover:text-gray-300" />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <FaInstagram className="text-2xl hover:text-gray-300" />
            </a> */}
            <a href="https://wa.me/+917418482830" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            <FaWhatsapp className="text-2xl hover:text-gray-300" /></a>
          </div>
        </div>
      </div>

      {/* Floating WhatsApp Button */}
      <a
        href="https://wa.me/+917418482830"
        className="fixed bottom-4 right-4 bg-green-500 text-white p-4 rounded-full shadow-lg"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="WhatsApp"
      >
        <FaWhatsapp className="text-2xl" />
      </a>
    </footer>
  );
};

export default Footer;

import React from 'react';
import TrainerProfile from '../components/TrainerProfile';

const Training = () => {
  return (
    <section className="py-12 px-6 bg-gray-100 min-h-screen">
      <h2 className="text-4xl font-bold text-center mb-12 text-purple-700">Training Programs</h2>
      <p className="text-lg text-center mb-8 text-gray-700">
        We provide comprehensive training for aspiring beauty professionals. Our programs are designed to equip you with the skills and knowledge needed to excel in the beauty industry.
      </p>

      <div className="flex flex-col md:flex-row justify-around">
        <TrainerProfile 
          name="lakshmi priya" 
          bio="lakshmi is an expert makeup artist with over 10 years of experience. She has trained numerous professionals and is passionate about sharing her knowledge and techniques."
          image="https://example.com/jane.jpg"
          specialties={["Makeup Artistry", "Bridal Makeup", "Special Effects"]}
          courses={["Basic Makeup", "Advanced Techniques", "Special Effects Makeup"]}
        />

        <TrainerProfile 
          name="harini" 
          bio="harini  specializes in hairstyling and has a passion for teaching. With a background in salon management and education, harini has helped many stylists master the art of hairdressing."
          image="https://example.com/john.jpg"
          specialties={["Hair Cutting", "Coloring", "Styling"]}
          courses={["Hair Cutting Techniques", "Color Mastery", "Styling Essentials"]}
        />
      </div>

      <div className="my-12 text-center">
        <h3 className="text-2xl font-semibold mb-4 text-purple-600">Hear from Our Trainees</h3>
        <div className="flex flex-wrap justify-center">
          <div className="w-80 bg-white shadow-lg rounded-lg p-6 m-4">
            <p className="italic">"The training program was transformative. lakshmi's expertise in makeup artistry opened new horizons for me!"</p>
            <p className="mt-2 font-bold">- Dharshini</p>
          </div>
          <div className="w-80 bg-white shadow-lg rounded-lg p-6 m-4">
            <p className="italic">"harini's hair styling course was incredibly thorough. I feel more confident in my skills now!"</p>
            <p className="mt-2 font-bold">- Vijaya Lakshmi</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Training;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/home/header/logo.png';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-gray-100 text-purple-700 p-4 flex items-center justify-around shadow-md relative">
      {/* Logo */}
      <div className="flex items-center md:w-1/3">
        <img src={logo} alt="Glowspotup" className="w-20 h-20 sm:w-18 sm:h-18" />
      </div>
      <div className='flex flex-col items-center md:w-1/3'>
        <div className="text-xl sm:text-3xl font-bold">Glowspotup</div>
        <div className="text-lg sm:text-xl italic text-purple-600">
          We spot here to glow you up
        </div>
      </div>
      {/* Desktop Navigation */}
      <nav className="hidden md:flex space-x-6 md:w-1/3 justify-end">
        <ul className="flex space-x-4">
          <li><Link to="/" className="hover:text-purple-500 text-lg sm:text-xl">Home</Link></li>
          <li><Link to="/services" className="hover:text-purple-500 text-lg sm:text-xl">Services</Link></li>
          <li><Link to="/training" className="hover:text-purple-500 text-lg sm:text-xl">Training</Link></li>
          <li><Link to="/contact" className="hover:text-purple-500 text-lg sm:text-xl">Contact</Link></li>
        </ul>
      </nav>

      {/* Mobile Menu Button */}
      <div className="md:hidden">
        <button 
          onClick={toggleMenu} 
          className="text-purple-700 focus:outline-none"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        </button>
      </div>

      {/* Mobile Navigation with Slide Down Animation */}
      <div 
        className={`absolute top-16 left-0 w-full bg-gray-100 rounded-b-lg shadow-lg z-10 transition-all duration-300 ease-in-out transform ${
          isOpen ? 'opacity-100 scale-y-100' : 'opacity-0 scale-y-0'
        } origin-top`}
      >
        <nav className={`flex flex-col space-y-4 p-6 ${isOpen ? 'block' : 'hidden'}`}>
          <ul className="flex flex-col space-y-4">
            <li><Link to="/" className="hover:text-purple-500 text-lg">Home</Link></li>
            <li><Link to="/services" className="hover:text-purple-500 text-lg">Services</Link></li>
            <li><Link to="/training" className="hover:text-purple-500 text-lg">Training</Link></li>
            <li><Link to="/contact" className="hover:text-purple-500 text-lg">Contact</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;

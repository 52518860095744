import React, { useState } from 'react';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobileNumber: '',
    message: ''
  });

  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.mobileNumber) {
      newErrors.mobileNumber = "Mobile Number is required";
    } else if (!/^\d{10}$/.test(formData.mobileNumber)) {
      newErrors.mobileNumber = 'Mobile Number must be 10 digits';
    }
    if (!formData.message) newErrors.message = 'Message is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage({ type: 'sending', message: 'Sending mail...' });
    if (validateForm()) {
      try {
        const response = await fetch('https://glowspotup.com/api/mail.php', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formData)
        });

        if (response.ok) {
          setErrorMessage({ type: 'success', message: 'Mail sent successfully!' });
          setFormData({
            name: '',
            mobileNumber: '',
            message: ''
          });
        } else {
          setErrorMessage({ type: 'error', message: 'Failed to send mail. Please try again.' });
        }
      } catch (error) {
        setErrorMessage({ type: 'error', message: 'Failed to send mail. Please try again.' });
        console.error('Error sending email:', error);
      }
    }
  };

  return (
    <section className="py-12 bg-gray-50">
      <div className="max-w-4xl mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-purple-700 mb-8">Contact Us</h2>
        <p className="text-lg text-center text-gray-600 mb-12">
          We're here to help you with any questions or bookings. Reach out to us, and we’ll get back to you as soon as possible.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
          <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center text-center md:text-left">
            <h3 className="text-2xl font-semibold text-purple-500 mb-4">Get in Touch</h3>
            <div className="flex items-center mb-4">
              <svg className="w-6 h-6 text-purple-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12h.01M6.5 8l.01.01M2 12h20M3 3v6a9 9 0 0 0 9 9h.02"></path>
              </svg>
              <span className="text-lg text-gray-700">Glowspotup@gmail.com</span>
            </div>
            <div className="flex items-center">
              <svg className="w-6 h-6 text-purple-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4M15 10V5a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v5M21 15l-9 9-9-9"></path>
              </svg>
              <span className="text-lg text-gray-700">+91 7418482830</span>
            </div>
          </div>

          <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center text-center md:text-right">
            <h3 className="text-2xl font-semibold text-purple-500 mb-4">Visit Us</h3>
            <p className="text-lg text-gray-700">
              Glow spotup beauty lounge, Tirnelveli
            </p>
          </div>
        </div>

        <div className="bg-white shadow-lg rounded-lg p-6 md:p-8">
          <h3 className="text-2xl font-semibold text-gray-800 mb-4 text-center">Send Us a Message</h3>

          {errorMessage && (
            <div
              className={`text-center w-full p-5 mb-3 rounded-md text-white font-bold ${
                errorMessage.type === 'sending' ? 'bg-yellow-400' :
                errorMessage.type === 'success' ? 'bg-green-400' : 'bg-red-400'
              }`}
            >
              {errorMessage.message}
            </div>
          )}

          <form className="space-y-4" onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Your Name"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
            {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}

            <input
              type="text"
              placeholder="Your Mobile Number"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
              name="mobileNumber"
              onChange={handleInputChange}
              value={formData.mobileNumber}
            />
            {errors.mobileNumber && <p className="text-red-500 text-sm">{errors.mobileNumber}</p>}

            <textarea
              placeholder="Your Message"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
              rows="4"
              name="message"
              onChange={handleInputChange}
              value={formData.message}
            />
            {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}

            <button
              type="submit"
              className="w-full bg-purple-500 text-white p-3 rounded-lg hover:bg-purple-600 transition"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;

import React, { useState } from 'react';
import banner from '../assets/images/home/banner.png'
import leader1 from '../assets/images/home/about/leader1.jpg'
import leader2 from '../assets/images/home/about/leader2.jpg'
import leader3 from '../assets/images/home/about/leader3.PNG'
import makeup from '../assets/images/home/service/makeup.jpg'
import blackneck from '../assets/images/home/service/blackneck.jpg'
import bleach from '../assets/images/home/service/bleach.jpg'
import bridal from '../assets/images/home/service/bridal.jpg'
import detan from '../assets/images/home/service/detan.jpg'
import facial from '../assets/images/home/service/facial.jpg'
import haircolor from '../assets/images/home/service/haircolor.jpg'
import hairspa from '../assets/images/home/service/hairspa.jpg'
import hairstyle from '../assets/images/home/service/hairstyle.jpg'
import haircare from '../assets/images/home/service/haricare.jpg'
import headmassage from '../assets/images/home/service/headmassage.jpg'
import manicure from '../assets/images/home/service/manicure.jpg'
import pedicure from '../assets/images/home/service/pedicure.jpg'
import skincare from '../assets/images/home/service/skincare.jpg'
import threading from '../assets/images/home/service/threading.jpg'
import waxing from '../assets/images/home/service/waxing.jpg'
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper-bundle.min.css';
// import 'swiper/swiper.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Swiper core styles
import 'swiper/css/pagination'; // Swiper pagination styles
import 'swiper/css/scrollbar'; // Swiper scrollbar styles
import { Autoplay, Pagination, Scrollbar } from 'swiper/modules';
import { Link } from 'react-router-dom';

import userwomen from "../assets/images/home/testimonial/userwomen.png"

const Home = () => {
    const [faqOpen, setFaqOpen] = useState({});
    const services = [
      { 
        id: 1, 
        title: "Makeup", 
        description: "Professional makeup services for all occasions, including weddings, parties, and photo shoots. We use high-quality products to ensure a flawless finish.",
        image: makeup 
      },
      { 
        id: 2, 
        title: "Hair Styling", 
        description: "Expert hair styling at your convenience, tailored to your personal style and the latest trends. We offer cuts, coloring, and special event styles.",
        image: hairstyle 
      },
      { 
        id: 3, 
        title: "Skincare", 
        description: "Personalized skincare treatments for glowing skin, including facials, exfoliation, and anti-aging therapies. Our services are designed to rejuvenate and refresh your skin.", 
        image: skincare 
      },
      { 
        id: 4, 
        title: "Hair Care", 
        description: "Specialized hair care treatments to keep your hair healthy and shiny. Includes deep conditioning and damage repair solutions." ,
        image: haircare
      },
      { 
        id: 5, 
        title: "Head Massage", 
        description: "Relaxing head massages to relieve stress and promote relaxation. Ideal for boosting circulation and reducing headaches." ,
        image: headmassage
      },
      { 
        id: 6, 
        title: "Hair Spa", 
        description: "Rejuvenating hair spa treatments to nourish your scalp and hair, making them smooth and revitalized." ,
        image: hairspa
      },
      { 
        id: 7, 
        title: "Facial & Clean Up", 
        description: "Deep-cleansing facials to remove impurities, exfoliate dead skin cells, and hydrate your skin for a radiant glow.",
        image: facial  
      },
      { 
        id: 8, 
        title: "Black Neck Treatment", 
        description: "Special treatments to lighten and cleanse the neck area, ensuring even-toned and smooth skin." ,
        image: blackneck
      },
      { 
        id: 9, 
        title: "Threading", 
        description: "Precision threading services for perfectly shaped eyebrows and hair removal." ,
        image: threading
      },
      { 
        id: 10, 
        title: "Manicure", 
        description: "Professional manicure services to clean, shape, and beautify your nails, leaving them looking flawless." ,
        image: manicure
      },
      { 
        id: 11, 
        title: "Pedicure", 
        description: "Soothing pedicure treatments for your feet, focusing on cleaning, exfoliation, and relaxation." ,
        image: pedicure
      },
      { 
        id: 12, 
        title: "Waxing", 
        description: "Efficient and gentle waxing services for hair removal, leaving your skin smooth and soft." ,
        image: waxing
      },
      { 
        id: 13, 
        title: "Hair Coloring & Henna Care", 
        description: "Professional hair coloring services and henna treatments to add vibrancy and shine to your hair." ,
        image: haircolor
      },
      { 
        id: 14, 
        title: "Bleach", 
        description: "Facial and body bleaching services to lighten and brighten your skin tone." ,
        image: bleach
      },
      { 
        id: 15, 
        title: "Detan", 
        description: "Special detan treatments to remove sun tan and bring out your skin's natural glow." ,
        image: detan
      },
      { 
        id: 16, 
        title: "Bridal Makeup", 
        description: "Exclusive bridal makeup services for the big day, ensuring a stunning and long-lasting look." ,
        image: bridal
      }
    ];
    
  const toggleFaq = (index) => {
    setFaqOpen((prevFaqOpen) => ({
      ...prevFaqOpen,
      [index]: !prevFaqOpen[index],
    }));
  };
  return (
    <section className="text-center p-8 bg-gray-100 min-h-screen">
      <h2 className="text-4xl font-bold mb-6 text-purple-700">Welcome to Our glow spotup  salon, lounge,home services, bridal studio & training acadamy</h2>
      <p className="mb-4 text-lg">Offering professional beauty services at your doorstep.</p>
      
      {/* Hero Section */}
      <div className="bg-cover bg-center mb-6">
        <img src={banner} alt="" />
        <div className="bg-black bg-opacity-50 h-full flex items-center justify-center">
          {/* <p className="text-white text-2xl">Experience the Best Beauty Services</p> */}
        </div>
      </div>

      
<div className="my-8 bg-white shadow-md rounded-lg p-6">
  <h3 className="text-3xl font-semibold mb-4 text-purple-700">About Us</h3>
  <p className="mb-4 text-lg">
    At Glow Spotup  Salon, lounge,home services, bridal studio & training acadamy, we believe in the power of enhancing natural beauty through exceptional care and expertise. Founded by Taj Nisha Mohammed, our mission is to provide luxurious beauty services right to your doorstep, tailored to your unique needs and preferences.
  </p>
  <p className="mb-4 text-lg">
    Our team of experienced professionals is not only skilled in their craft but also passionate about empowering women through beauty. With a focus on continuous learning and using high-quality products, we ensure that every service we offer meets the highest standards.
  </p>
  <p className="mb-4 text-lg">
    What sets us apart is our commitment to a personalized beauty experience. We take the time to understand your needs, provide expert recommendations, and ensure you feel comfortable and confident every step of the way. We use to take self grooming sessions in schools, colleges etc..
  </p>
  <div className="flex flex-wrap justify-center mt-6">
    <div className="w-64 text-center m-4">
      <img src={leader1} alt="Team Member 1" className="rounded-full w-40 h-40 mx-auto mb-2"/>
      {/* <h4 className="text-xl font-bold">[Team Member 1]</h4> */}
      {/* <p className="text-sm">Makeup Artist</p> */}
    </div>
    <div className="w-64 text-center m-4">
      <img src={leader2} alt="Team Member 2" className="rounded-full w-40 h-40 mx-auto mb-2"/>
      {/* <h4 className="text-xl font-bold">[Team Member 2]</h4> */}
      {/* <p className="text-sm">Hair Stylist</p> */}
    </div>
    <div className="w-64 text-center m-4">
      <img src={leader3} alt="Team Member 3" className="rounded-full w-40 h-40 mx-auto mb-2"/>
      {/* <h4 className="text-xl font-bold">[Team Member 3]</h4> */}
      {/* <p className="text-sm">Skincare Specialist</p> */}
    </div>
  </div>
</div>


      {/* Services Overview */}
      {/* Our Services */}
<div className="my-8">
  <h3 className="text-3xl font-semibold mb-6 text-center text-purple-700">Our Services</h3>
  {/* <div className="flex flex-wrap justify-center items-center">
  <Swiper
        spaceBetween={0}
        slidesPerView={3} // Adjust the number of visible cards here
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }} // Auto-scroll with 3-second delay
        modules={[Autoplay]}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1, // Show 1 slide on small screens (mobile)
            spaceBetween: 0,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 2, // Show 2 slides on medium screens (tablets)
            spaceBetween: 10,
          },
          // when window width is >= 1024px
          1024: {
            slidesPerView: 3, // Show 3 slides on large screens (desktops)
            spaceBetween: 0,
          },
        }}
      >
    {services.map((service, key)=>{return(
      <SwiperSlide>
    <div className="w-full md:w-72 bg-purple-100 shadow-lg rounded-lg p-6 m-4 hover:scale-105 transform transition-transform duration-300 border ">
    {service.image ? 
      <img src={service.image} alt="Makeup" className="w-full h-40 object-cover rounded-t-lg mb-4" />
    :
      <img src="https://dummyimage.com/600x400/000/fff" alt="Makeup" className="w-full h-40 object-cover rounded-t-lg mb-4" />
    }
      <h4 className="text-xl font-bold mb-2">{service.title.toUpperCase()}</h4>
      <p className="text-gray-600">{service.description}</p>
    </div>
    </SwiperSlide>
    )})}
    </Swiper> */}
    {/* <div className="flex flex-wrap justify-center items-center w-full">
  <Swiper
    spaceBetween={20}
    slidesPerView={3} // Adjust the number of visible cards here
    pagination={{ clickable: true }}
    scrollbar={{ draggable: true }}
    autoplay={{ delay: 3000, disableOnInteraction: false }} // Auto-scroll with 3-second delay
    modules={[Autoplay]}
    breakpoints={{
      320: {
        slidesPerView: 1, // Show 1 slide on small screens (mobile)
        spaceBetween: 10,
      },
      640: {
        slidesPerView: 2, // Show 2 slides on medium screens (tablets)
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3, // Show 3 slides on large screens (desktops)
        spaceBetween: 30,
      },
    }}
  >
    {services.map((service, key) => (
      <SwiperSlide key={key}>
        <div className="w-full md:w-72 bg-white shadow-lg rounded-lg p-6 transition-transform duration-300 hover:scale-105 transform border border-gray-200">
          <img 
            src={service.image || "https://dummyimage.com/600x400/000/fff"} 
            alt={service.title}
            className="w-full h-40 object-cover rounded-lg mb-4"
          />
          <h4 className="text-lg font-bold mb-2 text-gray-800">{service.title.toUpperCase()}</h4>
          <p className="text-gray-600">{service.description}</p>
        </div>
      </SwiperSlide>
    ))}
  </Swiper> */}
<div className="container mx-auto px-4 py-8">
  <Swiper
    spaceBetween={30}
    slidesPerView={3}
    pagination={{ clickable: true }}
    scrollbar={{ draggable: true }}
    autoplay={{ delay: 3000, disableOnInteraction: false }}
    modules={[Autoplay]}
    breakpoints={{
      320: {
        slidesPerView: 1, // Mobile view: Show 1 slide
        spaceBetween: 10,
      },
      640: {
        slidesPerView: 2, // Tablet view: Show 2 slides
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 4, // Desktop view: Show 3 slides
        spaceBetween: 30,
      },
    }}
  >
    {services.map((service, key) => (
      <SwiperSlide key={key}>
        <div className="max-w-sm w-full bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-2xl">
          <img 
            src={service.image || "https://dummyimage.com/600x400/000/fff"} 
            alt={service.title}
            className="w-full h-48 object-cover"
          />
          <div className="p-6">
            <h4 className="text-xl font-semibold mb-2 text-gray-800">{service.title.toUpperCase()}</h4>
            <p className="text-gray-600 mb-4">{service.description}</p>
          </div>
        </div>
      </SwiperSlide>
    ))}
  </Swiper>

    {/* <div className="w-72 bg-purple-100 shadow-lg rounded-lg p-6 m-4 hover:scale-105 transform transition-transform duration-300">
      <img src="https://dummyimage.com/600x400/000/fff" alt="Hair Styling" className="w-full h-40 object-cover rounded-t-lg mb-4" />
      <h4 className="text-xl font-bold mb-2">Hair Styling</h4>
      <p className="text-gray-600">Expert hair styling at your convenience, tailored to your personal style and the latest trends. We offer cuts, coloring, and special event styles.</p>
    </div>
    <div className="w-72 bg-purple-100 shadow-lg rounded-lg p-6 m-4 hover:scale-105 transform transition-transform duration-300">
      <img src="https://dummyimage.com/600x400/000/fff" alt="Skincare" className="w-full h-40 object-cover rounded-t-lg mb-4" />
      <h4 className="text-xl font-bold mb-2">Skincare</h4>
      <p className="text-gray-600">Personalized skincare treatments for glowing skin, including facials, exfoliation, and anti-aging therapies. Our services are designed to rejuvenate and refresh your skin.</p>
    </div> */}
  
  </div>
</div>


      {/* Gallery */}
      {/* <div className="my-8 bg-white shadow-md rounded-lg p-6">
        <h3 className="text-3xl font-semibold mb-4 text-purple-700">Gallery</h3>
        <div className="flex flex-wrap justify-center">
          <img className="w-64 h-48 object-cover m-2 rounded-md" src="https://dummyimage.com/600x400/000/fff" alt="Gallery Image 1" />
          <img className="w-64 h-48 object-cover m-2 rounded-md" src="https://dummyimage.com/600x400/000/fff" alt="Gallery Image 2" />
          <img className="w-64 h-48 object-cover m-2 rounded-md" src="https://dummyimage.com/600x400/000/fff" alt="Gallery Image 3" />
          <img className="w-64 h-48 object-cover m-2 rounded-md" src="https://dummyimage.com/600x400/000/fff" alt="Gallery Image 3" />
          <img className="w-64 h-48 object-cover m-2 rounded-md" src="https://dummyimage.com/600x400/000/fff" alt="Gallery Image 3" />
          <img className="w-64 h-48 object-cover m-2 rounded-md" src="https://dummyimage.com/600x400/000/fff" alt="Gallery Image 3" />
        </div>
      </div> */}

      {/* Testimonials */}
<div className="my-8 bg-gray-100 p-6 rounded-lg">
  <h3 className="text-3xl font-semibold mb-4 text-center text-purple-700">What Our Clients Say</h3>
  <div className="flex flex-wrap justify-center">
    <div className="w-80 bg-white shadow-md rounded-lg p-6 m-4 text-center">
      <img src={userwomen} alt="Jane Doe" className="w-16 h-16 rounded-full mx-auto mb-4" />
      <p className="italic">"I love the makeup service. They are professional and friendly."</p>
      <p className="mt-4 font-bold">- Kowsalya</p>
    </div>
    <div className="w-80 bg-white shadow-md rounded-lg p-6 m-4 text-center">
      <img src={userwomen} alt="John Smith" className="w-16 h-16 rounded-full mx-auto mb-4" />
      <p className="italic">"Amazing hairstyling experience. I highly recommend them!"</p>
      <p className="mt-4 font-bold">- Arjitha </p>
    </div>
    <div className="w-80 bg-white shadow-md rounded-lg p-6 m-4 text-center">
      <img src={userwomen} alt="Alex Johnson" className="w-16 h-16 rounded-full mx-auto mb-4" />
      <p className="italic">"Their skincare treatment left my skin glowing. I feel refreshed!"</p>
      <p className="mt-4 font-bold">- Jamal Fathima</p>
    </div>
  </div>
</div>


      {/* FAQs */}
      {/* FAQs */}
<div className="my-8 bg-white shadow-md rounded-lg p-6">
  <h3 className="text-3xl font-semibold mb-4 text-purple-700">FAQs</h3>
  <div className="w-full md:w-2/3 mx-auto text-left text-white">
    <div className="bg-purple-500 p-4 shadow-md rounded-lg mb-2 ">
      <h4 className="font-bold cursor-pointer" onClick={() => toggleFaq(1)}>
        Q: What services do you offer?
      </h4>
      <p className="mt-2" style={{ display: faqOpen[1] ? 'block' : 'none' }}>
        A: We offer a range of beauty services including makeup, hair styling, and personalized skincare treatments.
      </p>
    </div>
    <div className="bg-purple-500 p-4 shadow-md rounded-lg mb-2">
      <h4 className="font-bold cursor-pointer" onClick={() => toggleFaq(2)}>
        Q: How do I book an appointment?
      </h4>
      <p className="mt-2" style={{ display: faqOpen[2] ? 'block' : 'none' }}>
        A: You can book an appointment by clicking the "Contact Us" button below or by calling us directly.
      </p>
    </div>
    <div className="bg-purple-500 p-4 shadow-md rounded-lg mb-2">
      <h4 className="font-bold cursor-pointer" onClick={() => toggleFaq(3)}>
        Q: What are your operating hours?
      </h4>
      <p className="mt-2" style={{ display: faqOpen[3] ? 'block' : 'none' }}>
        A: We operate from 9 AM to 7 PM, Monday through Saturday. Appointments outside these hours can be arranged on request.
      </p>
    </div>
    <div className="bg-purple-500 p-4 shadow-md rounded-lg mb-2">
      <h4 className="font-bold cursor-pointer" onClick={() => toggleFaq(4)}>
        Q: Do you use cruelty-free products?
      </h4>
      <p className="mt-2" style={{ display: faqOpen[4] ? 'block' : 'none' }}>
        A: Yes, we are committed to using only cruelty-free products in our services.
      </p>
    </div>
  </div>
</div>


      {/* Call-to-Action */}
      <div className="my-8 bg-purple-500 text-white p-6 rounded-lg">
        <h3 className="text-2xl font-bold mb-4">Book Your Appointment Today!</h3>
        <Link to="/contact"><button className="bg-white text-purple-500 px-4 py-2 rounded hover:bg-purple-700 hover:text-white transition-colors">
          Contact Us
        </button></Link>
      </div>
    </section>
  );
};

export default Home;

// import React from 'react';

// const ServiceCard = ({ title, description }) => {
//   return (
//     <div className="bg-white shadow-lg rounded-lg p-6 hover:bg-purple-100 transition-colors duration-300">
//       <h2 className="text-xl font-bold mb-2">{title}</h2>
//       <p>{description}</p>
//     </div>
//   );
// };

// export default ServiceCard;


import React, { useState } from 'react';
import Modal from './Modal'; // Ensure you import the Modal component

const ServiceCard = ({ service }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
console.log(service);
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <img src={service.image} alt={service.title} className="w-full h-80 object-cover" />
      <div className="p-4">
        <h3 className="text-xl font-semibold text-purple-700 mb-2">{service.title}</h3>
        <p className="text-gray-600 mb-2">{service.description.substring(0, 80)}...</p>
        <button 
          onClick={handleOpenModal} 
          className="bg-purple-600 text-white py-2 px-4 rounded hover:bg-purple-700"
        >
          Details
        </button>
        <Modal 
          isOpen={isModalOpen} 
          onClose={handleCloseModal} 
          service={service} 
        />
      </div>
    </div>
  );
};

export default ServiceCard;
